<template>
  <div class="people">
      <h2>People page</h2>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>