var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scrollBox",staticClass:"scroll-box",class:{
    'owf-x-visible': _vm.scrollx === 'visible',
    'owf-y-visible': _vm.scrolly === 'visible',
    'owf-x-hidden': _vm.scrollx === 'hidden',
    'owf-y-hidden': _vm.scrolly === 'hidden',
    'owf-x-clip': _vm.scrollx === 'clip',
    'owf-y-clip': _vm.scrolly === 'clip',
    'owf-x-scroll': _vm.scrollx === 'scroll',
    'owf-y-scroll': _vm.scrolly === 'scroll'
    },on:{"scroll":_vm.onScroll}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }